<template>
    <modal
        transition="nice-modal-fade"
        class="new-modal-default"
        :name="modal_name"
        :delay="100"
        height="auto"
        :adaptive="true"
        :scrollable="false"
    >
        <div class="v-modal-content" style="border-radius: 15px;background-color: #eff1f4 !important;">
            <!-- <table>
                <th>
                    <td>
                        <input type="checkbox" style="border: 1px solid #0475f3;" />
                    </td>
                    <td class="text-uppercase">contact name</td>
                    <td class="text-uppercase">job title</td>
                </th>
                <tr>
                    <td>
                        <input type="checkbox" style="border: 1px solid #0475f3;" />
                    </td>
                    <td>
                        Shyam
                    </td>
                    <td>
                        CEO
                    </td>
                </tr>
                <tr>
                    <td>
                        <input type="checkbox" style="border: 1px solid #0475f3;" />
                    </td>
                    <td>
                        Mohan
                    </td>
                    <td>
                        Senior VP
                    </td>
                </tr>
                <tr>
                    <td>
                        <input type="checkbox" style="border: 1px solid #0475f3;" />
                    </td>
                    <td>
                        Ramesh
                    </td>
                    <td>
                        VP
                    </td>
                </tr>
                <tr>
                    <td>
                        <input type="checkbox" style="border: 1px solid #0475f3;" />
                    </td>
                    <td>
                        Shyam
                    </td>
                    <td>
                        CEO
                    </td>
                </tr>
                <tr>
                    <td>
                        <input type="checkbox" style="border: 1px solid #0475f3;" />
                    </td>
                    <td>
                        Shyam
                    </td>
                    <td>
                        CEO
                    </td>
                </tr>
            </table> -->
            <table>
                <thead style="background-color: #00448b;">
                    <tr>
                        <!-- <th class="p-2"><input type="checkbox" style="border: 1px solid #0475f3;" /></th> -->
                        <el-checkbox class="p-2"></el-checkbox>
                        <th class="p-2 text-white">Contact Name</th>
                        <th class="p-2 text-white">Job title</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <!-- <td class="p-2"><input type="checkbox" style="border: 1px solid #0475f3;" /></td> -->
                        <el-checkbox class="p-2"></el-checkbox>
                        <td class="p-2" style="color:#00448b">Shyam</td>
                        <td class="p-2">CEO</td>
                    </tr>
                    <tr>
                        <!-- <td class="p-2"><input type="checkbox" style="border: 1px solid #0475f3;" /></td> -->
                        <el-checkbox class="p-2"></el-checkbox>
                        <td class="p-2" style="color:#00448b">Mohan</td>
                        <td class="p-2">Senior VP</td>
                    </tr>
                    <tr>
                        <!-- <td class="p-2"><input type="checkbox" style="border: 1px solid #0475f3;" /></td> -->
                        <el-checkbox class="p-2"></el-checkbox>
                        <td class="p-2" style="color:#00448b">Ramesh</td>
                        <td class="p-2">VP</td>
                    </tr>
                    <tr>
                        <!-- <td class="p-2"><input type="checkbox" style="border: 1px solid #0475f3;" /></td> -->
                        <el-checkbox class="p-2"></el-checkbox>
                        <td class="p-2" style="color:#00448b">Ghanshyam</td>
                        <td class="p-2">Manager</td>
                    </tr>
                    <tr>
                        <!-- <td class="p-2"><input type="checkbox" style="border: 1px solid #0475f3;" /></td> -->
                        <el-checkbox class="p-2"></el-checkbox>
                        <td class="p-2" style="color:#00448b">Gokul</td>
                        <td class="p-2">Sales Team Lead</td>
                    </tr>
                    <tr>
                        <!-- <td class="p-2"><input type="checkbox" style="border: 1px solid #0475f3;" /></td> -->
                        <el-checkbox class="p-2"></el-checkbox>
                        <td class="p-2" style="color:#00448b">Samuel</td>
                        <td class="p-2">Salesman</td>
                    </tr>
                </tbody>
            </table>
            <div class="d-flex justify-content-center p-2">
                <button class="btn btn-outline-secondary" @click="HideSelectContactModal()">Cancel</button>
                <button class="btn btn-success text-white ml-3">Save</button>
            </div>
        </div>
    </modal>
</template>

<script>

export default({
    props: ['modal_name'],
    data(){
        return{

        }
    },
    methods:{
        HideSelectContactModal(){
            this.$emit('hide-select-contact-modal')
        }
    }
})

</script>

<style scoped>
    .w-100{
        width: 100%;
    }
</style>
